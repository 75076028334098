
import { defineComponent } from 'vue';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import initInfo, { breadHeaders } from '@/views/pm/old-community/resident/module/info/init-info';
import initSubList from '@/views/pm/old-community/resident/module/info/init-sub-list';
import AddButton from '@/components/common/add-button/index.vue';
import router, { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import { listPagination } from '@/components/common/list/index';
import SetSubDialog from '@/components/view/pm/set-old-resident-dialog';
import PayApp from '@/components/view/common/subscription/module/pay/pay-app.vue';
import { tipsDialog as paymentTipsDialog } from '@/components/view/common/payment/index';
import { community } from '@/data';

export default defineComponent({
    components: {
        PropertyBreadCrumb,
        AddButton,
        listPagination,
        SetSubDialog,
        PayApp,
        paymentTipsDialog
    },
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const projectType = community.ProjectType;
        const propId = props.id;
        const routerToEdit = () => {
            router.push(
                `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldSetResident}?id=${propId}`
            );
        };

        const {
            infoData,
            mainEmail
        } = initInfo(propId);

        const {
            headers,
            deleteUrl,
            updateList,
            add,
            edit,
            requestData,
            isShowSetSubDialog,
            subFormData,
            submit,
            opera,
            payAppId,
            parentId,
            isShowPay,
            action,
            isShowTips,
            isShowPayNow,
            noticeType,
            noticeMainCode
        } = initSubList(propId);

        return {
            breadHeaders,
            infoData,
            routerToEdit,
            headers,
            deleteUrl,
            updateList,
            edit,
            requestData,
            add,
            isShowSetSubDialog,
            subFormData,
            submit,
            opera,
            payAppId,
            parentId,
            isShowPay,
            action,
            isShowTips,
            isShowPayNow,
            noticeType,
            noticeMainCode,
            mainEmail,
            projectType
        };
    }
});
