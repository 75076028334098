import { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import { ref, Ref } from 'vue';
import { residentApi } from '@/api';
import { getPhoneCodeName } from '@/data';

const breadHeaders: {label: string;path?: string}[] = [
    {
        label: WordList.RDeviceResidents,
        path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResident}`
    },
    {
        label: WordList.PersonuserInfo
    }
];

const initInfo = (
    mainId: string
) => {
    const infoData: Ref<
        Array< {name: string; label: string; content: string} >
        > = ref(
            [
                {
                    name: 'UnitName',
                    label: WordList.DeviceDetailDetailUnitName,
                    content: ''
                }, {
                    name: 'RoomName',
                    label: WordList.RDeviceSearchLabelRoomName,
                    content: ''
                }, {
                    name: 'AptName',
                    label: WordList.RDeviceSearchLabelRoomNumber,
                    content: ''
                }, {
                    name: 'Name',
                    label: WordList.PersonuserFamilyMaster,
                    content: ''
                }, {
                    name: 'Email',
                    label: WordList.TabelPropertyManageEmail,
                    content: ''
                }, {
                    name: 'Phone',
                    label: WordList.TabelPersonUserInHtmlPhone,
                    content: ''
                }, {
                    name: 'PhoneCode',
                    label: WordList.ProperAllTextCountryRegion,
                    content: ''
                }, {
                    name: 'MobileNumber',
                    label: WordList.ProperAllTextMobileNumber,
                    content: ''
                }, {
                    name: 'CreateTime',
                    label: WordList.TabelMessageBoxCreateTime,
                    content: ''
                }, {
                    name: 'ActiveTime',
                    label: WordList.RDeviceListTanleActive,
                    content: ''
                }]
        );

    const mainEmail = ref('');

    residentApi.getUserInfoOldPm({ ID: mainId },
        (data: { [key in string]: string }) => {
            infoData.value.forEach((item, index) => {
                if (item.name === 'Phone') {
                    infoData.value[index].content = `${data.Phone !== '' ? `+${data.PhoneCode}${data.Phone},` : ''}${data.Phone2 !== ''
                        ? `+${data.PhoneCode}${data.Phone2},` : ''}${data.Phone3 !== '' ? `+${data.PhoneCode}${data.Phone3},` : ''}`;
                } else if (item.name === 'PhoneCode') {
                    infoData.value[index].content = getPhoneCodeName(data[item.name]) || '--';
                } else if (item.name === 'Email') {
                    mainEmail.value = data[item.name] || '';
                    infoData.value[index].content = data[item.name] || '--';
                } else {
                    infoData.value[index].content = data[item.name] || '--';
                }
            });
        });

    return {
        infoData,
        mainEmail
    };
};

export default initInfo;
export {
    breadHeaders
};