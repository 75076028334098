import { reactive, ref } from 'vue';
import { isCNServer } from '@/util/location';
import { ListActionItem, RequestData } from '@/components/common/list';
import { SubFormData } from '@/components/view/pm/set-old-resident-dialog/all.type';
import { community } from '@/data';
import noticeBase from '@/util/notice.base';
import createSubCallback from '@/methods/createSubCallback';

const initSubList = (
    mainId: string
) => {
    // 删除地址
    const deleteUrl = 'v3/web/community/subUser/delete';

    // 表头
    const headers = reactive([
        {
            name: 'Name',
            label: WordList.TabelPersonUserInHtmlName
        }, {
            name: 'Email',
            label: WordList.TabelPropertyManageEmail
        }, {
            name: 'MobileNumber',
            label: WordList.ProperAllTextMobileNumber
        }, {
            name: 'CreateTime',
            label: WordList.TabelMessageBoxCreateTime
        }
    ]);
    if (isCNServer()) {
        headers.splice(1, 1);
    }

    // 表格查询
    const updateList = ref(0);
    const requestData: RequestData = reactive({
        url: 'v3/web/community/user/getSubList',
        param: {
            ID: mainId
        }
    });

    // 弹窗
    const isShowSetSubDialog = ref(false);
    const subFormData = ref();
    const opera = ref<'add'|'edit'>('add');

    // 额外app pay
    const payAppId = ref('');
    const parentId = ref('');
    const isShowPay = ref(false);
    const action: Array<ListActionItem> = [{
        type: 'pay',
        event(value: { ID: string; ParentID: string }) {
            if (community.ChargeMode.value === 1) {
                noticeBase.messageBox(
                    'alert',
                    WordList.AdditionalAppPermissionsNotice,
                    WordList.PersonuserInfo,
                    'info'
                )(() => true);
                return;
            }
            payAppId.value = value.ID;
            parentId.value = mainId;
            isShowPay.value = true;
        },
        class: 'modify-icon el-icon-my-pay',
        showCondition(val) {
            return val.Active === '0';
        },
        title: WordList.OrderPay
    }];

    const add = () => {
        opera.value = 'add';
        subFormData.value = {};
        isShowSetSubDialog.value = true;
    };

    const edit = (sub: SubFormData) => {
        opera.value = 'edit';
        subFormData.value = { ...sub, Email: sub.Email || '' };
        isShowSetSubDialog.value = true;
    };

    // v6.4 ,控制展示支付弹窗和pay by app ,zh
    const isShowTips = ref(false);
    const isShowPayNow = () => {
        isShowTips.value = false;
        isShowPay.value = true;
    };

    const noticeType = ref('');
    const noticeMainCode = ref();
    const addUserSuccess = (res: {
        msg: string;
        ID: string;
        Active: 0|1;
        TipCode: number;
    }) => {
        createSubCallback(
            res,
            (type: 'main'|'sub'|'') => {
                isShowTips.value = false;
                noticeMainCode.value = res.TipCode;
                noticeType.value = type;
                parentId.value = mainId;
                payAppId.value = res.ID;
                isShowTips.value = true;
            }
        );
    };
    const submit = (res: {
        msg: string;
        ID: string;
        Active: 0|1;
        TipCode: number;
    }) => {
        updateList.value += 1;
        isShowSetSubDialog.value = false;
        if (res) {
            addUserSuccess(res);
        }
    };

    return {
        deleteUrl,
        headers,
        updateList,
        add,
        edit,
        requestData,
        isShowSetSubDialog,
        subFormData,
        submit,
        opera,
        payAppId,
        parentId,
        isShowPay,
        action,
        isShowTips,
        isShowPayNow,
        noticeType,
        noticeMainCode
    };
};
export default initSubList;